import { Container } from '@material-ui/core';
import Layout from '../../layout';
import React from 'react';

const AvisoPrivacidad = () => (
    <Layout footer>
            <Container>
                <h2 style={{ marginBottom:0, fontSize: '18pt', color:'#CC0A25' }} className="title">AVISO DE</h2>
                <h1 style={{ marginTop: 0, fontSize: '24pt' }} className="title">PRIVACIDAD INTEGRAL</h1>
                <p>AVISO DE PRIVACIDAD INTEGRAL En cumplimiento con los art&iacute;culos 15, 16 y dem&aacute;s relativos a la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de Particulares y su reglamento, se pone a su disposici&oacute;n el presente Aviso de Privacidad, el cual tiene por objeto informarle sobre el tratamiento que se le dar&aacute; a los datos personales que sean recabados, almacenados, utilizados o transferidos por la empresa denominada DIBLAGA S.A. DE C.V., de conformidad con los siguientes puntos:</p>
                <ol>
                <li>La identidad y domicilio del responsable que recaba datos personales: DIBLAGA S.A. DE C. V. es una persona jur&iacute;dica constituida de conformidad con la legislaci&oacute;n mexicana quien es la responsable de dar tratamiento, recabar, proteger y preservar los datos personales, incluyendo aquellos denominados sensibles, patrimoniales y financieros. Cuyo objeto principal es la fabricaci&oacute;n de blancos y ropa de decoraci&oacute;n. Que se&ntilde;ala como domicilio para o&iacute;r y recibir todo tipo de notificaciones la calle Marconi con el n&uacute;mero 270, Colonia General Real, en el municipio de Guadalajara, Jalisco, M&eacute;xico, C.P. 44400.</li>
                <li>Forma de obtenci&oacute;n de datos personales: Podemos obtener informaci&oacute;n a trav&eacute;s de los siguientes medios:</li>
                <li>a) Cuando nos la proporciona directamente por escrito;</li>
                <li>b) En forma verbal;</li>
                <li>c) Por medio de entrevista;</li>
                <li>d) Formularios en internet o electr&oacute;nicos;</li>
                <li>e) Servicios telef&oacute;nicos;</li>
                <li>f) Presente su solicitud de empleo o documento curricular;</li>
                </ol>
                <ul>
                <li>Los datos que recabamos: Los datos personales que obtenemos para cumplir con las finalidades establecidas en el presente aviso son:</li>
                </ul>
                <p>1) Nombre</p>
                <p>2) Estado Civil</p>
                <p>3) Registro Federal de Contribuyentes(RFC)</p>
                <p>4) Clave &uacute;nica de Registro de Poblaci&oacute;n (CURP)</p>
                <p>5) Lugar de nacimiento</p>
                <p>6) Fecha de nacimiento</p>
                <p>7) Nacionalidad</p>
                <p>8) Domicilio</p>
                <p>9) Tel&eacute;fono particular</p>
                <p>10) Tel&eacute;fono celular</p>
                <p>11) Correo electr&oacute;nico</p>
                <p>12) Firma aut&oacute;grafa</p>
                <p>13) Firma electr&oacute;nica</p>
                <p>14) Edad</p>
                <p>15) Fotograf&iacute;a</p>
                <p>16) Color de la piel</p>
                <p>17) Color del iris</p>
                <p>18) Color del cabello</p>
                <p>19) Se&ntilde;as particulares</p>
                <p>20) Estatura</p>
                <p>21) Peso</p>
                <p>22) Cicatrices</p>
                <p>23) Tipo de sangre</p>
                <p>24) Imagen del iris</p>
                <p>25) Huella dactilar</p>
                <p>26) Palma de la mano</p>
                <p>27) Puesto o cargo que desempe&ntilde;a</p>
                <p>28) Domicilio de trabajo</p>
                <p>29) Tel&eacute;fono institucional</p>
                <p>30) Referencias laborales</p>
                <p>31) Informaci&oacute;n generada durante los procesos de reclutamiento, selecci&oacute;n y contrataci&oacute;n</p>
                <p>32) Capacitaci&oacute;n laboral</p>
                <p>33) Trayectoria educativa</p>
                <p>34) T&iacute;tulos</p>
                <p>35) C&eacute;dula profesional</p>
                <p>36) Certificados</p>
                <p>37) Reconocimientos</p>
                <p>38) Entradas al pa&iacute;s</p>
                <p>39) Salidas del pa&iacute;s</p>
                <p>40) Tiempo de permanencia en el pa&iacute;s</p>
                <p>41) Calidad migratoria</p>
                <p>42) Derechos de residencia</p>
                <p>43) Aseguramiento</p>
                <p>44) Repatriaci&oacute;n</p>
                <p>45) Pasatiempos</p>
                <p>46) Aficiones</p>
                <p>47) Deportes que practica</p>
                <p>48) Juegos de su inter&eacute;s</p>
                <p>49) Datos de identificaci&oacute;n</p>
                <p>50) Datos de contacto</p>
                <p>51) Datos sobre caracter&iacute;sticas f&iacute;sicas</p>
                <p>52) Datos biom&eacute;tricos</p>
                <p>53) Datos laborales</p>
                <p>54) Datos acad&eacute;micos</p>
                <p>55) Datos migratorios</p>
                <p>Adem&aacute;s de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales sensibles, patrimoniales y financieros que requieren de especial protecci&oacute;n:</p>
                <p>1) Religi&oacute;n que profesa</p>
                <p>2) Pertenencia a un sindicato</p>
                <p>3) Estado de salud f&iacute;sico presente, pasado o futuro</p>
                <p>4) Estado de salud mental presente, pasado o futuro</p>
                <p>5) Pertenencia a un pueblo, etnia o regi&oacute;n</p>
                <p>6) Bienes muebles</p>
                <p>7) Bienes inmuebles</p>
                <p>8) Informaci&oacute;n fiscal</p>
                <p>9) Historial crediticio</p>
                <p>10) Ingresos</p>
                <p>11) Egresos</p>
                <p>12) Cuentas bancarias</p>
                <p>13) N&uacute;mero de tarjetas de cr&eacute;dito</p>
                <p>14) Seguros</p>
                <p>15) Afores</p>
                <p>&nbsp;</p>
                <p>En el caso de los Datos Sensibles, Financieros o Patrimoniales es obligatorio que, de conformidad con la legislaci&oacute;n en la materia, nos otorgue su consentimiento expreso para darles tratamiento. Por lo que le solicitamos nos firme los formatos que pondremos a su disposici&oacute;n.</p>
                <p>&nbsp;</p>
                <ol>
                <li>Las finalidades del tratamiento de datos;</li>
                </ol>
                <p>&nbsp;Los datos personales del titular ser&aacute;n utilizados por DIBLAGA S.A. DE C. V. para las siguientes finalidades, en su car&aacute;cter de entidad responsable de la protecci&oacute;n de sus datos personales:</p>
                <ol>
                <li>Finalidades principales:</li>
                <li>a) Proveer los productos o servicios que ofrecemos;</li>
                <li>b) Medio para dar cumplimiento a obligaciones contra&iacute;das;</li>
                <li>c) Para enviarle facturas;</li>
                <li>d) Dar seguimiento al proceso de cobranza;</li>
                <li>e) &hellip;</li>
                <li>Finalidad dirigida a los Empleados:</li>
                <li>a) Para el proceso de evaluaci&oacute;n, reclutamiento y selecci&oacute;n de personal;</li>
                <li>b) Para la administraci&oacute;n de su expediente laboral;</li>
                <li>c) Integraci&oacute;n de los documentos necesarios para el desempe&ntilde;o de sus servicios;</li>
                <li>d) Pago de n&oacute;mina y dem&aacute;s prestaciones laborales;</li>
                <li>e) Cualquier otra finalidad compatible o an&aacute;loga a los fines previamente establecidos.</li>
                <li>Finalidad dirigida a los Proveedores:</li>
                <li>a) Solicitarle productos y servicios necesarios;</li>
                <li>b) Con el objeto de facturarles;</li>
                <li>c) Solicitarle garant&iacute;a de los productos o servicios;</li>
                <li>Finalidades Secundarias:</li>
                </ol>
                <p>De manera adicional, utilizaremos su informaci&oacute;n personal para las siguientes finalidades secundarias, que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atenci&oacute;n:</p>
                <ol>
                <li>a) Circulares informativas;</li>
                <li>b) Evaluaciones de calidad de los servicios o productos que contrat&oacute;;</li>
                <li>c) Para enviarle informaci&oacute;n comercial, servicios o promociones;</li>
                <li>d) Para invitarlo a eventos a realizar;</li>
                </ol>
                <p>En caso de que no desee que sus datos personales sean tratados para estos fines secundarios, usted puede enviar desde este momento un correo electr&oacute;nico ventas@diblaga.com.mx dirigido a Angel Saavedra, Ventas para manifestando su negativa para ello.</p>
                <p>La negativa para el uso de sus datos personales para finalidades secundarias, no podr&aacute; ser un motivo para que le neguemos los servicios que solicita o contrata con nosotros.</p>
                <p>&nbsp;</p>
                <ol>
                <li>Las opciones y medios que ofrecemos a los titulares para limitar el uso o divulgaci&oacute;n de los datos:</li>
                </ol>
                <p>DIBLAGA S.A. DE C. V., con la finalidad de resguardar de la mejor manera posible su informaci&oacute;n se compromete a tratar sus datos personales bajo estrictas medidas de seguridad para garantizar su confidencialidad. Se han establecido est&aacute;ndares de seguridades f&iacute;sicas, administrativas, legales y tecnol&oacute;gicas para evitar que terceros no autorizados se apoderen de sus datos personales.</p>
                <p>Adem&aacute;s, existen procedimientos administrativos mediante los cuales puede limitar la publicidad por llamadas de tel&eacute;fono denominado Registro P&uacute;blico para Evitar Publicidad que proporciona la Procuradur&iacute;a Federal del Consumidor, que puede consultar en la p&aacute;gina de internet: <a href="http://rpc.profeco.gob.mx/Solicitudnumero.jsp">http://rpc.profeco.gob.mx/Solicitudnumero.jsp</a></p>
                <p>Cabe mencionar que el titular de los datos personales puede iniciar un procedimiento de Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n (Por sus siglas ARCO) para limitar el uso o divulgar sus datos de conformidad con el cap&iacute;tulo</p>
                <ol>
                <li>Del presente aviso de privacidad.</li>
                </ol>
                <ul>
                <li>Los medios para ejercer los derechos de Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n u Oposici&oacute;n (ARCO) de los datos personales.</li>
                </ul>
                <p>&nbsp;El titular podr&aacute; ejercer sus derechos de rectificaci&oacute;n, acceso, cancelaci&oacute;n u oposici&oacute;n al tratamiento de sus datos, por sus siglas ARCO, presentando una solicitud de conformidad con el siguiente procedimiento:</p>
                <p>Presentar en nuestro domicilio un escrito dirigido a ANGEL ERNESTO SAAVEDRA SANCHEZ, en su calidad responsable, que debe incluir los siguientes datos y documentos:</p>
                <ol>
                <li>a) El nombre del titular de los datos personales, su domicilio y un medio alterno para comunicarle la respuesta a su solicitud;</li>
                <li>b) Presentar una identificaci&oacute;n oficial vigente;</li>
                <li>c) Los documentos que acrediten la identidad del titular de los datos personales o, en su caso, si lo hace un tercero acreditar la representaci&oacute;n legal;</li>
                <li>d) La descripci&oacute;n clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos de ARCO; y</li>
                <li>e) Cualquier otro elemento o documento que facilite la localizaci&oacute;n de sus Datos Personales; y</li>
                <li>f) En su caso, las solicitudes de rectificaci&oacute;n de datos personales, el titular deber&aacute; indicar las modificaciones a realizarse y aportar la documentaci&oacute;n que sustente su petici&oacute;n.</li>
                <li>g) En caso de que se aprecie que la documentaci&oacute;n tenga tachaduras, este alterada o se tenga duda razonable de ser ap&oacute;crifa, que no sea el titular, o no ser el leg&iacute;timo representante, DIBLAGA S.A DE C. V. se reserva el derecho a solicitar cualquier otro documento para comprobar y acreditar la titularidad de los datos, por lo que se le podr&aacute; solicitar que presente la documentaci&oacute;n en original o copias certificadas en la oficina del responsable.</li>
                </ol>
                <p>Si faltara alguna informaci&oacute;n en su solicitud se le solicitar&aacute; subsane la omisi&oacute;n a la brevedad.</p>
                <p>Si cumpliera con todas las formalidades requeridas para dicho tr&aacute;mite se dar&aacute; respuesta en un plazo m&aacute;ximo de veinte d&iacute;as, contados a partir de la fecha en que se recibi&oacute; su petici&oacute;n o a partir de la fecha en que haya subsanado en su totalidad las prevenciones. El plazo antes referido podr&aacute; ser ampliado una sola vez por un periodo igual, siempre y cuando as&iacute; lo justifiquen las circunstancias del caso. De conformidad con el art&iacute;culo 32 de la ley de protecci&oacute;n de datos personales en posesi&oacute;n de particulares.</p>
                <p>&nbsp;</p>
                <ul>
                <li>Revocaci&oacute;n del Tratamiento de Datos Personales.</li>
                </ul>
                <p>&nbsp;Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales por lo que solicitamos nos env&iacute;e su petici&oacute;n al correo ventas@diblaga.com.mx dirigido a ANGEL SAAVEDRA SANCHEZ VENTAS. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute; o la conclusi&oacute;n de su relaci&oacute;n con nosotros.</p>
                <p>VII. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En su caso, las transferencias de datos que se efect&uacute;en.</p>
                <p>Le hacemos de su conocimiento que DIBLAGA S.A DE C.V. no realiza transferencia de datos a terceros. Adem&aacute;s, le informamos que podremos realizar transferencias sin su consentimiento de acuerdo a lo previsto en el art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de datos Personales en Posesi&oacute;n de los Particulares, as&iacute; como a realizar esta transferencia en los t&eacute;rminos que fija esa ley, a lo que establecemos lo supuesto:</p>
                <ol>
                <li>a) Cuando la transferencia est&eacute; prevista en una Ley;</li>
                <li>b) Cuando la transferencia sea necesaria para la prevenci&oacute;n o el diagn&oacute;stico m&eacute;dico, la prestaci&oacute;n de asistencia sanitaria, tratamiento m&eacute;dico o la gesti&oacute;n de servicios sanitarios;</li>
                <li>c) Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control com&uacute;n del responsable, o a una sociedad matriz o a cualquier sociedad del mismo Grupo del responsable que opere bajo los mismos procesos y pol&iacute;ticas internas;</li>
                <li>d) Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en inter&eacute;s del titular, por el responsable y un tercero;</li>
                <li>e) Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un inter&eacute;s p&uacute;blico, o para la procuraci&oacute;n o administraci&oacute;n de justicia;</li>
                <li>f) Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial; y</li>
                <li>g) Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relaci&oacute;n jur&iacute;dica entre el responsable y el titular.</li>
                </ol>
                <p>VIII. Uso de Cookies, Web Beacons o cualquier otra tecnolog&iacute;a an&aacute;loga. Le informamos que DIBLAGA SA DE C. V. no utilizamos cookies, web beacons, ni cualquier otra tecnolog&iacute;a an&aacute;loga. IX. El medio y procedimiento mediante el cual el responsable comunicar&aacute; a los titulares de cambios al aviso de privacidad, de conformidad con lo previsto en la Ley de la materia. DIBLAGA S.A. de C. V. se reserva el derecho de efectuar, en cualquier momento, modificaciones y adici&oacute;n en todo o en parte del presente aviso de privacidad. Toda modificaci&oacute;n se notificar&aacute; mediante un aviso en el portal de internet denominado: www.diblaga.com.mx, propiedad de la empresa.</p>
        </Container>
    </Layout>
);

export default AvisoPrivacidad;